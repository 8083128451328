import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71925d34 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _29da7260 = () => interopDefault(import('../pages/checkout/order/created.vue' /* webpackChunkName: "pages/checkout/order/created" */))
const _5eb66ffa = () => interopDefault(import('../pages/b2b/index.vue' /* webpackChunkName: "pages/b2b/index" */))
const _51085f6a = () => interopDefault(import('../pages/cms/blog.vue' /* webpackChunkName: "pages/cms/blog" */))
const _0f047300 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b0ef2cea = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _c31079f2 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _197d9d9a = () => interopDefault(import('../pages/auth/login/seller.vue' /* webpackChunkName: "pages/auth/login/seller" */))
const _6c901c60 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _2d584ed1 = () => interopDefault(import('../pages/categories/pleated-blinds.vue' /* webpackChunkName: "pages/categories/pleated-blinds" */))
const _544f6290 = () => interopDefault(import('../pages/static/montage.vue' /* webpackChunkName: "pages/static/montage" */))
const _661e669d = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _1838c360 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _615a0e26 = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _09de3fae = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _4d169312 = () => interopDefault(import('../pages/products/category.vue' /* webpackChunkName: "pages/products/category" */))
const _29bdcf5b = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _120dc874 = () => interopDefault(import('../pages/auth/register/seller.vue' /* webpackChunkName: "pages/auth/register/seller" */))
const _48d493e0 = () => interopDefault(import('../pages/categories/roller-blinds.vue' /* webpackChunkName: "pages/categories/roller-blinds" */))
const _4c15171f = () => interopDefault(import('../pages/categories/relax.vue' /* webpackChunkName: "pages/categories/relax" */))
const _84f82a4a = () => interopDefault(import('../pages/print.vue' /* webpackChunkName: "pages/print" */))
const _e32de180 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _0dc98f3e = () => interopDefault(import('../pages/categories/curtain.vue' /* webpackChunkName: "pages/categories/curtain" */))
const _2df774e2 = () => interopDefault(import('../pages/categories/curtains.vue' /* webpackChunkName: "pages/categories/curtains" */))
const _b763c29c = () => interopDefault(import('../pages/surcharge.vue' /* webpackChunkName: "pages/surcharge" */))
const _74c1586c = () => interopDefault(import('../pages/admin/bank-transfer.vue' /* webpackChunkName: "pages/admin/bank-transfer" */))
const _53e1a69b = () => interopDefault(import('../pages/admin/invoice.vue' /* webpackChunkName: "pages/admin/invoice" */))
const _050e9104 = () => interopDefault(import('../pages/admin/messenger-statistic.vue' /* webpackChunkName: "pages/admin/messenger-statistic" */))
const _444a4718 = () => interopDefault(import('../pages/b2b/success.vue' /* webpackChunkName: "pages/b2b/success" */))
const _9d5a9040 = () => interopDefault(import('../pages/profile/change-password.vue' /* webpackChunkName: "pages/profile/change-password" */))
const _1b183c90 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _5f082948 = () => interopDefault(import('../pages/profile/comments.vue' /* webpackChunkName: "pages/profile/comments" */))
const _7b9c6872 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _3d119a76 = () => interopDefault(import('../pages/profile/tradeCredit.vue' /* webpackChunkName: "pages/profile/tradeCredit" */))
const _a6af557e = () => interopDefault(import('../pages/messenger.vue' /* webpackChunkName: "pages/messenger" */))
const _3bc4593c = () => interopDefault(import('../pages/auth/confirm-phone.vue' /* webpackChunkName: "pages/auth/confirm-phone" */))
const _0dcb3012 = () => interopDefault(import('../pages/admin/sizes/add.vue' /* webpackChunkName: "pages/admin/sizes/add" */))
const _682611ef = () => interopDefault(import('../pages/admin/product/add.vue' /* webpackChunkName: "pages/admin/product/add" */))
const _0ededff4 = () => interopDefault(import('../pages/admin/change-password.vue' /* webpackChunkName: "pages/admin/change-password" */))
const _0687bfdc = () => interopDefault(import('../pages/admin/offer/add.vue' /* webpackChunkName: "pages/admin/offer/add" */))
const _66eebb73 = () => interopDefault(import('../pages/admin/offer/list.vue' /* webpackChunkName: "pages/admin/offer/list" */))
const _02c61d84 = () => interopDefault(import('../pages/admin/descriptionTemplates/list.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/list" */))
const _3807c62a = () => interopDefault(import('../pages/admin/orders/index.vue' /* webpackChunkName: "pages/admin/orders/index" */))
const _47fde8c4 = () => interopDefault(import('../pages/admin/clientGroups/index.vue' /* webpackChunkName: "pages/admin/clientGroups/index" */))
const _e76e2ca0 = () => interopDefault(import('../pages/admin/profile-edit.vue' /* webpackChunkName: "pages/admin/profile-edit" */))
const _5342f069 = () => interopDefault(import('../pages/admin/filters.vue' /* webpackChunkName: "pages/admin/filters" */))
const _481951fd = () => interopDefault(import('../pages/admin/sizes/list.vue' /* webpackChunkName: "pages/admin/sizes/list" */))
const _ebadbf40 = () => interopDefault(import('../pages/admin/coupons&groupDeal.vue' /* webpackChunkName: "pages/admin/coupons&groupDeal" */))
const _4629f41c = () => interopDefault(import('../pages/admin/tradeCredit/list.vue' /* webpackChunkName: "pages/admin/tradeCredit/list" */))
const _3ab0bcba = () => interopDefault(import('../pages/admin/spreadsheet.vue' /* webpackChunkName: "pages/admin/spreadsheet" */))
const _05e91ec7 = () => interopDefault(import('../pages/admin/clients/list.vue' /* webpackChunkName: "pages/admin/clients/list" */))
const _5c4eac2e = () => interopDefault(import('../pages/admin/marketing/list.vue' /* webpackChunkName: "pages/admin/marketing/list" */))
const _c05dc12c = () => interopDefault(import('../pages/admin/employees/list.vue' /* webpackChunkName: "pages/admin/employees/list" */))
const _56413e01 = () => interopDefault(import('../pages/admin/messenger.vue' /* webpackChunkName: "pages/admin/messenger" */))
const _2a0f59d2 = () => interopDefault(import('../pages/admin/cms/page.vue' /* webpackChunkName: "pages/admin/cms/page" */))
const _3645ceb0 = () => interopDefault(import('../pages/admin/cms/post.vue' /* webpackChunkName: "pages/admin/cms/post" */))
const _71a73176 = () => interopDefault(import('../pages/admin/pricesOnQuantity.vue' /* webpackChunkName: "pages/admin/pricesOnQuantity" */))
const _391aabc0 = () => interopDefault(import('../pages/admin/product/list.vue' /* webpackChunkName: "pages/admin/product/list" */))
const _75f989fa = () => interopDefault(import('../pages/admin/store/edit.vue' /* webpackChunkName: "pages/admin/store/edit" */))
const _01064300 = () => interopDefault(import('../pages/admin/cms/updatePage.vue' /* webpackChunkName: "pages/admin/cms/updatePage" */))
const _0a29eede = () => interopDefault(import('../pages/admin/cms/updatePost.vue' /* webpackChunkName: "pages/admin/cms/updatePost" */))
const _4b5ce4b0 = () => interopDefault(import('../pages/admin/shipping.vue' /* webpackChunkName: "pages/admin/shipping" */))
const _b269f86e = () => interopDefault(import('../pages/admin/tools.vue' /* webpackChunkName: "pages/admin/tools" */))
const _2b7280f4 = () => interopDefault(import('../pages/admin/payment.vue' /* webpackChunkName: "pages/admin/payment" */))
const _22821a63 = () => interopDefault(import('../pages/admin/clientGroups/manage.vue' /* webpackChunkName: "pages/admin/clientGroups/manage" */))
const _20323b0b = () => interopDefault(import('../pages/admin/groupDeal/add.vue' /* webpackChunkName: "pages/admin/groupDeal/add" */))
const _dc4864e0 = () => interopDefault(import('../pages/admin/groupDeal/edit.vue' /* webpackChunkName: "pages/admin/groupDeal/edit" */))
const _085d8105 = () => interopDefault(import('../pages/admin/employees/add.vue' /* webpackChunkName: "pages/admin/employees/add" */))
const _1ab3b69e = () => interopDefault(import('../pages/admin/descriptionTemplates/add.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/add" */))
const _65898679 = () => interopDefault(import('../pages/admin/orders/add.vue' /* webpackChunkName: "pages/admin/orders/add" */))
const _4c0b2aa6 = () => interopDefault(import('../pages/admin/marketing/add.vue' /* webpackChunkName: "pages/admin/marketing/add" */))
const _0de518aa = () => interopDefault(import('../pages/admin/descriptionTemplates/edit.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/edit" */))
const _3dbe5d56 = () => interopDefault(import('../pages/admin/marketing/edit.vue' /* webpackChunkName: "pages/admin/marketing/edit" */))
const _a1cd7254 = () => interopDefault(import('../pages/admin/employees/edit.vue' /* webpackChunkName: "pages/admin/employees/edit" */))
const _1106bf2c = () => interopDefault(import('../pages/auth/confirm-email.vue' /* webpackChunkName: "pages/auth/confirm-email" */))
const _b4a7ba10 = () => interopDefault(import('../pages/profile/orders/_id.vue' /* webpackChunkName: "pages/profile/orders/_id" */))
const _598323a9 = () => interopDefault(import('../pages/admin/clients/details.vue' /* webpackChunkName: "pages/admin/clients/details" */))
const _67e4b71e = () => interopDefault(import('../pages/admin/groupDeal/deals.vue' /* webpackChunkName: "pages/admin/groupDeal/deals" */))
const _7636e2df = () => interopDefault(import('../pages/admin/offer/edit.vue' /* webpackChunkName: "pages/admin/offer/edit" */))
const _04454b12 = () => interopDefault(import('../pages/admin/orders/_id.vue' /* webpackChunkName: "pages/admin/orders/_id" */))
const _4862d32c = () => interopDefault(import('../pages/admin/product/edit.vue' /* webpackChunkName: "pages/admin/product/edit" */))
const _57617969 = () => interopDefault(import('../pages/admin/sizes/edit.vue' /* webpackChunkName: "pages/admin/sizes/edit" */))
const _00112ec4 = () => interopDefault(import('../pages/admin/tradeCredit/details.vue' /* webpackChunkName: "pages/admin/tradeCredit/details" */))
const _1cb2a48e = () => interopDefault(import('../pages/groupDeal.vue' /* webpackChunkName: "pages/groupDeal" */))
const _323270ce = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _a459ced2 = () => interopDefault(import('../pages/cms/postPage.vue' /* webpackChunkName: "pages/cms/postPage" */))
const _1608acd8 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _71925d34,
    props: false,
    name: "404___de"
  }, {
    path: "/auftragsbezogen",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___de"
  }, {
    path: "/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___de"
  }, {
    path: "/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___de"
  }, {
    path: "/en",
    component: _0f047300,
    props: false,
    name: "index___en"
  }, {
    path: "/es",
    component: _0f047300,
    props: false,
    name: "index___es"
  }, {
    path: "/fr",
    component: _0f047300,
    props: false,
    name: "index___fr"
  }, {
    path: "/it",
    component: _0f047300,
    props: false,
    name: "index___it"
  }, {
    path: "/Kasse",
    component: _b0ef2cea,
    props: false,
    name: "checkout___de"
  }, {
    path: "/Login",
    component: _c31079f2,
    props: false,
    name: "auth-login___de"
  }, {
    path: "/Login-Verkaufer",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___de"
  }, {
    path: "/nl",
    component: _0f047300,
    props: false,
    name: "index___nl"
  }, {
    path: "/no",
    component: _0f047300,
    props: false,
    name: "index___no"
  }, {
    path: "/Passwort-vergessen",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___de"
  }, {
    path: "/plissee",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___de"
  }, {
    path: "/plissee-montage-video",
    component: _544f6290,
    props: false,
    name: "static-montage___de"
  }, {
    path: "/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___de"
    }]
  }, {
    path: "/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/Produkt",
      component: _09de3fae,
      name: "products___de"
    }, {
      path: "/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___de"
    }]
  }, {
    path: "/Register",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___de"
  }, {
    path: "/Register-Verkaufer",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___de"
  }, {
    path: "/rollo",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___de"
  }, {
    path: "/spannbettlaken",
    component: _4c15171f,
    props: false,
    name: "categories-relax___de"
  }, {
    path: "/uberdrucken",
    component: _84f82a4a,
    props: false,
    name: "print___de"
  }, {
    path: "/Verkaufer-Panel",
    component: _e32de180,
    props: false,
    name: "admin___de"
  }, {
    path: "/vorhang",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___de"
  }, {
    path: "/vorhange",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___de"
  }, {
    path: "/zuzahlung",
    component: _b763c29c,
    props: false,
    name: "surcharge___de"
  }, {
    path: "/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___de"
  }, {
    path: "/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___de"
  }, {
    path: "/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___de"
  }, {
    path: "/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___de"
  }, {
    path: "/en/404",
    component: _71925d34,
    props: false,
    name: "404___en"
  }, {
    path: "/en/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___en"
  }, {
    path: "/en/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___en"
  }, {
    path: "/en/checkout",
    component: _b0ef2cea,
    props: false,
    name: "checkout___en"
  }, {
    path: "/en/curtains",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___en"
  }, {
    path: "/en/fitted-sheets",
    component: _4c15171f,
    props: false,
    name: "categories-relax___en"
  }, {
    path: "/en/forgot-password",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___en"
  }, {
    path: "/en/login",
    component: _c31079f2,
    props: false,
    name: "auth-login___en"
  }, {
    path: "/en/order-created",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___en"
  }, {
    path: "/en/pleated-blinds",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___en"
  }, {
    path: "/en/pleated-blinds-montage-videos",
    component: _544f6290,
    props: false,
    name: "static-montage___en"
  }, {
    path: "/en/print",
    component: _84f82a4a,
    props: false,
    name: "print___en"
  }, {
    path: "/en/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/en/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___en"
    }]
  }, {
    path: "/en/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/en/products",
      component: _09de3fae,
      name: "products___en"
    }, {
      path: "/en/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___en"
    }]
  }, {
    path: "/en/register",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___en"
  }, {
    path: "/en/roller-blinds",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___en"
  }, {
    path: "/en/seller-login",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___en"
  }, {
    path: "/en/seller-panel",
    component: _e32de180,
    props: false,
    name: "admin___en"
  }, {
    path: "/en/seller-register",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___en"
  }, {
    path: "/en/surcharge",
    component: _b763c29c,
    props: false,
    name: "surcharge___en"
  }, {
    path: "/en/vorhang",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___en"
  }, {
    path: "/es/404",
    component: _71925d34,
    props: false,
    name: "404___es"
  }, {
    path: "/es/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___es"
  }, {
    path: "/es/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___es"
  }, {
    path: "/es/caja-registradora",
    component: _b0ef2cea,
    props: false,
    name: "checkout___es"
  }, {
    path: "/es/cortinas",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___es"
  }, {
    path: "/es/estores-enrollables",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___es"
  }, {
    path: "/es/estores-plisados",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___es"
  }, {
    path: "/es/He-olvidado-mi-contrasena",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___es"
  }, {
    path: "/es/imprimir",
    component: _84f82a4a,
    props: false,
    name: "print___es"
  }, {
    path: "/es/Iniciar-sesion",
    component: _c31079f2,
    props: false,
    name: "auth-login___es"
  }, {
    path: "/es/Iniciar-sesion-como-vendedor",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___es"
  }, {
    path: "/es/pago-adicional",
    component: _b763c29c,
    props: false,
    name: "surcharge___es"
  }, {
    path: "/es/panel-Vendedor",
    component: _e32de180,
    props: false,
    name: "admin___es"
  }, {
    path: "/es/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/es/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___es"
    }]
  }, {
    path: "/es/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/es/producto",
      component: _09de3fae,
      name: "products___es"
    }, {
      path: "/es/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___es"
    }]
  }, {
    path: "/es/Registrarse",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___es"
  }, {
    path: "/es/Registrarse-como-vendedor",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___es"
  }, {
    path: "/es/relacionado-con-el-pedido",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___es"
  }, {
    path: "/es/sabanas-bajeras",
    component: _4c15171f,
    props: false,
    name: "categories-relax___es"
  }, {
    path: "/es/videos-de-montaje-de-persianas-plisadas",
    component: _544f6290,
    props: false,
    name: "static-montage___es"
  }, {
    path: "/es/vorhang",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___es"
  }, {
    path: "/fr/404",
    component: _71925d34,
    props: false,
    name: "404___fr"
  }, {
    path: "/fr/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___fr"
  }, {
    path: "/fr/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___fr"
  }, {
    path: "/fr/Connexion",
    component: _c31079f2,
    props: false,
    name: "auth-login___fr"
  }, {
    path: "/fr/draps-housses",
    component: _4c15171f,
    props: false,
    name: "categories-relax___fr"
  }, {
    path: "/fr/enregistrement-vendeur",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___fr"
  }, {
    path: "/fr/imprimer",
    component: _84f82a4a,
    props: false,
    name: "print___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___fr"
  }, {
    path: "/fr/ordre-cree",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___fr"
  }, {
    path: "/fr/paiement-supplementaire",
    component: _b763c29c,
    props: false,
    name: "surcharge___fr"
  }, {
    path: "/fr/panneau-vendeur",
    component: _e32de180,
    props: false,
    name: "admin___fr"
  }, {
    path: "/fr/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/fr/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___fr"
    }]
  }, {
    path: "/fr/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/fr/des-produits",
      component: _09de3fae,
      name: "products___fr"
    }, {
      path: "/fr/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___fr"
    }]
  }, {
    path: "/fr/rideau",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___fr"
  }, {
    path: "/fr/Sinscrire",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___fr"
  }, {
    path: "/fr/store-enrouleur",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___fr"
  }, {
    path: "/fr/store-plisse",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___fr"
  }, {
    path: "/fr/vendeur-connexion",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___fr"
  }, {
    path: "/fr/verifier",
    component: _b0ef2cea,
    props: false,
    name: "checkout___fr"
  }, {
    path: "/fr/videos-dassemblage-de-stores-plisses",
    component: _544f6290,
    props: false,
    name: "static-montage___fr"
  }, {
    path: "/fr/vorhang",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___fr"
  }, {
    path: "/it/404",
    component: _71925d34,
    props: false,
    name: "404___it"
  }, {
    path: "/it/accesso",
    component: _c31079f2,
    props: false,
    name: "auth-login___it"
  }, {
    path: "/it/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___it"
  }, {
    path: "/it/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___it"
  }, {
    path: "/it/cassa",
    component: _b0ef2cea,
    props: false,
    name: "checkout___it"
  }, {
    path: "/it/curtains",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___it"
  }, {
    path: "/it/ho-dimenticato-la-password",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___it"
  }, {
    path: "/it/lenzuola-con-angoli",
    component: _4c15171f,
    props: false,
    name: "categories-relax___it"
  }, {
    path: "/it/login-venditore",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___it"
  }, {
    path: "/it/ordine-creato",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___it"
  }, {
    path: "/it/pannello-venditori",
    component: _e32de180,
    props: false,
    name: "admin___it"
  }, {
    path: "/it/pleated-blinds",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___it"
  }, {
    path: "/it/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/it/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___it"
    }]
  }, {
    path: "/it/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/it/prodotti",
      component: _09de3fae,
      name: "products___it"
    }, {
      path: "/it/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___it"
    }]
  }, {
    path: "/it/registrazione",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___it"
  }, {
    path: "/it/roller-blinds",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___it"
  }, {
    path: "/it/sovrastampa",
    component: _84f82a4a,
    props: false,
    name: "print___it"
  }, {
    path: "/it/sovvenzione",
    component: _b763c29c,
    props: false,
    name: "surcharge___it"
  }, {
    path: "/it/venditore-di-registrazioni",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___it"
  }, {
    path: "/it/videos-installazione-istruzioni-per-le-pieghe",
    component: _544f6290,
    props: false,
    name: "static-montage___it"
  }, {
    path: "/it/vorhang",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___it"
  }, {
    path: "/mein-Konto/Anderungspasswort",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___de"
  }, {
    path: "/mein-Konto/Auftrage",
    component: _1b183c90,
    props: false,
    name: "profile-orders___de"
  }, {
    path: "/mein-Konto/Bewertungen",
    component: _5f082948,
    props: false,
    name: "profile-comments___de"
  }, {
    path: "/mein-Konto/editieren",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___de"
  }, {
    path: "/mein-Konto/Handelskredit",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___de"
  }, {
    path: "/mein-Konto/Mitteilungen",
    component: _a6af557e,
    props: false,
    name: "messenger___de"
  }, {
    path: "/nl/404",
    component: _71925d34,
    props: false,
    name: "404___nl"
  }, {
    path: "/nl/afdrukken",
    component: _84f82a4a,
    props: false,
    name: "print___nl"
  }, {
    path: "/nl/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___nl"
  }, {
    path: "/nl/bestelling-gemaakt",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___nl"
  }, {
    path: "/nl/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___nl"
  }, {
    path: "/nl/gordijnen",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___nl"
  }, {
    path: "/nl/hoeslakens",
    component: _4c15171f,
    props: false,
    name: "categories-relax___nl"
  }, {
    path: "/nl/login",
    component: _c31079f2,
    props: false,
    name: "auth-login___nl"
  }, {
    path: "/nl/montagevideo-voor-plissegordijnen",
    component: _544f6290,
    props: false,
    name: "static-montage___nl"
  }, {
    path: "/nl/plissegordijnen",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___nl"
  }, {
    path: "/nl/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/nl/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___nl"
    }]
  }, {
    path: "/nl/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/nl/producten",
      component: _09de3fae,
      name: "products___nl"
    }, {
      path: "/nl/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___nl"
    }]
  }, {
    path: "/nl/registeren",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___nl"
  }, {
    path: "/nl/rolgordijnen",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___nl"
  }, {
    path: "/nl/toeslag",
    component: _b763c29c,
    props: false,
    name: "surcharge___nl"
  }, {
    path: "/nl/uitchecken",
    component: _b0ef2cea,
    props: false,
    name: "checkout___nl"
  }, {
    path: "/nl/verkoper-login",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___nl"
  }, {
    path: "/nl/verkoper-paneel",
    component: _e32de180,
    props: false,
    name: "admin___nl"
  }, {
    path: "/nl/verkoper-registeren",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___nl"
  }, {
    path: "/nl/vorhang",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___nl"
  }, {
    path: "/nl/wachtwoord-vergeten",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___nl"
  }, {
    path: "/no/404",
    component: _71925d34,
    props: false,
    name: "404___no"
  }, {
    path: "/no/b2b",
    component: _5eb66ffa,
    props: false,
    name: "b2b___no"
  }, {
    path: "/no/blog",
    component: _51085f6a,
    props: false,
    name: "cms-blog___no"
  }, {
    path: "/no/gardin",
    component: _0dc98f3e,
    props: false,
    name: "categories-curtain___no"
  }, {
    path: "/no/gardiner",
    component: _2df774e2,
    props: false,
    name: "categories-curtains___no"
  }, {
    path: "/no/glemt-passord",
    component: _6c901c60,
    props: false,
    name: "auth-forgot-password___no"
  }, {
    path: "/no/innlogging",
    component: _c31079f2,
    props: false,
    name: "auth-login___no"
  }, {
    path: "/no/innlogging-leverandor",
    component: _197d9d9a,
    props: false,
    name: "auth-login-seller___no"
  }, {
    path: "/no/Kasse",
    component: _b0ef2cea,
    props: false,
    name: "checkout___no"
  }, {
    path: "/no/monteringsvideoer-for-plissegardiner",
    component: _544f6290,
    props: false,
    name: "static-montage___no"
  }, {
    path: "/no/ordre-opprettet",
    component: _29da7260,
    props: false,
    name: "checkout-order-created___no"
  }, {
    path: "/no/overtrykk",
    component: _84f82a4a,
    props: false,
    name: "print___no"
  }, {
    path: "/no/plissegardiner",
    component: _2d584ed1,
    props: false,
    name: "categories-pleated-blinds___no"
  }, {
    path: "/no/product",
    component: _661e669d,
    props: false,
    children: [{
      path: "/no/:productName-p:productId(\\d+)",
      component: _1838c360,
      name: "product___no"
    }]
  }, {
    path: "/no/products",
    component: _615a0e26,
    props: false,
    children: [{
      path: "/no/produkter",
      component: _09de3fae,
      name: "products___no"
    }, {
      path: "/no/:categoryName-c:categoryId(\\d+)",
      component: _4d169312,
      name: "products-category___no"
    }]
  }, {
    path: "/no/registrering",
    component: _29bdcf5b,
    props: false,
    name: "auth-register___no"
  }, {
    path: "/no/registrering-selger",
    component: _120dc874,
    props: false,
    name: "auth-register-seller___no"
  }, {
    path: "/no/rullegardiner",
    component: _48d493e0,
    props: false,
    name: "categories-roller-blinds___no"
  }, {
    path: "/no/selger-panel",
    component: _e32de180,
    props: false,
    name: "admin___no"
  }, {
    path: "/no/stretchlaken",
    component: _4c15171f,
    props: false,
    name: "categories-relax___no"
  }, {
    path: "/no/tilleggsavgift",
    component: _b763c29c,
    props: false,
    name: "surcharge___no"
  }, {
    path: "/Telefon/Bestatigung",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___de"
  }, {
    path: "/Verkaufer-Panel/Add-Grosse",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___de"
  }, {
    path: "/Verkaufer-Panel/Add-Product-hinzufugen",
    component: _682611ef,
    props: false,
    name: "admin-product-add___de"
  }, {
    path: "/Verkaufer-Panel/Anderungs-Passwort",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___de"
  }, {
    path: "/Verkaufer-Panel/Angebot-hinzufugen",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___de"
  }, {
    path: "/Verkaufer-Panel/Angebote",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___de"
  }, {
    path: "/Verkaufer-Panel/Beschreibungsvorlagen",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___de"
  }, {
    path: "/Verkaufer-Panel/Bestellungen",
    component: _3807c62a,
    props: false,
    name: "admin-orders___de"
  }, {
    path: "/Verkaufer-Panel/client-groups",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___de"
  }, {
    path: "/Verkaufer-Panel/editieren",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___de"
  }, {
    path: "/Verkaufer-Panel/Filter",
    component: _5342f069,
    props: false,
    name: "admin-filters___de"
  }, {
    path: "/Verkaufer-Panel/Grossen",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___de"
  }, {
    path: "/Verkaufer-Panel/gutscheine-deals",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___de"
  }, {
    path: "/Verkaufer-Panel/Handelskredit",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___de"
  }, {
    path: "/Verkaufer-Panel/Import-Export",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___de"
  }, {
    path: "/Verkaufer-Panel/Kunden",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___de"
  }, {
    path: "/Verkaufer-Panel/marketing",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___de"
  }, {
    path: "/Verkaufer-Panel/Mitarbeiter",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___de"
  }, {
    path: "/Verkaufer-Panel/Mitteilungen",
    component: _56413e01,
    props: false,
    name: "admin-messenger___de"
  }, {
    path: "/Verkaufer-Panel/page",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___de"
  }, {
    path: "/Verkaufer-Panel/post",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___de"
  }, {
    path: "/Verkaufer-Panel/preise-nach-menge",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___de"
  }, {
    path: "/Verkaufer-Panel/Produkte",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___de"
  }, {
    path: "/Verkaufer-Panel/Store-Bearbeitung",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___de"
  }, {
    path: "/Verkaufer-Panel/updatePage",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___de"
  }, {
    path: "/Verkaufer-Panel/updatePost",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___de"
  }, {
    path: "/Verkaufer-Panel/Versand",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___de"
  }, {
    path: "/Verkaufer-Panel/werkzeuge",
    component: _b269f86e,
    props: false,
    name: "admin-tools___de"
  }, {
    path: "/Verkaufer-Panel/Zahlung",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___de"
  }, {
    path: "/en/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___en"
  }, {
    path: "/en/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___en"
  }, {
    path: "/en/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___en"
  }, {
    path: "/en/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___en"
  }, {
    path: "/en/my-account/change-password",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___en"
  }, {
    path: "/en/my-account/comments",
    component: _5f082948,
    props: false,
    name: "profile-comments___en"
  }, {
    path: "/en/my-account/edit",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___en"
  }, {
    path: "/en/my-account/messages",
    component: _a6af557e,
    props: false,
    name: "messenger___en"
  }, {
    path: "/en/my-account/orders",
    component: _1b183c90,
    props: false,
    name: "profile-orders___en"
  }, {
    path: "/en/my-account/trade-credit",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___en"
  }, {
    path: "/en/phone/confirm",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___en"
  }, {
    path: "/en/seller-panel/add-offer",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___en"
  }, {
    path: "/en/seller-panel/add-product",
    component: _682611ef,
    props: false,
    name: "admin-product-add___en"
  }, {
    path: "/en/seller-panel/add-size",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___en"
  }, {
    path: "/en/seller-panel/change-password",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___en"
  }, {
    path: "/en/seller-panel/client-groups",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___en"
  }, {
    path: "/en/seller-panel/clients",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___en"
  }, {
    path: "/en/seller-panel/coupons-deals",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___en"
  }, {
    path: "/en/seller-panel/description-templates",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___en"
  }, {
    path: "/en/seller-panel/edit",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___en"
  }, {
    path: "/en/seller-panel/employees",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___en"
  }, {
    path: "/en/seller-panel/filters",
    component: _5342f069,
    props: false,
    name: "admin-filters___en"
  }, {
    path: "/en/seller-panel/import-export",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___en"
  }, {
    path: "/en/seller-panel/marketing",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___en"
  }, {
    path: "/en/seller-panel/messages",
    component: _56413e01,
    props: false,
    name: "admin-messenger___en"
  }, {
    path: "/en/seller-panel/offers",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___en"
  }, {
    path: "/en/seller-panel/orders",
    component: _3807c62a,
    props: false,
    name: "admin-orders___en"
  }, {
    path: "/en/seller-panel/page",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___en"
  }, {
    path: "/en/seller-panel/payment",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___en"
  }, {
    path: "/en/seller-panel/post",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___en"
  }, {
    path: "/en/seller-panel/prices-on-quantity",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___en"
  }, {
    path: "/en/seller-panel/products",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___en"
  }, {
    path: "/en/seller-panel/shipping",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___en"
  }, {
    path: "/en/seller-panel/sizes",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___en"
  }, {
    path: "/en/seller-panel/store-edition",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___en"
  }, {
    path: "/en/seller-panel/tools",
    component: _b269f86e,
    props: false,
    name: "admin-tools___en"
  }, {
    path: "/en/seller-panel/trade-credit",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___en"
  }, {
    path: "/en/seller-panel/updatePage",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___en"
  }, {
    path: "/en/seller-panel/updatePost",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___en"
  }, {
    path: "/es/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___es"
  }, {
    path: "/es/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___es"
  }, {
    path: "/es/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___es"
  }, {
    path: "/es/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___es"
  }, {
    path: "/es/mi-cuenta/cambiar-contrasena",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___es"
  }, {
    path: "/es/mi-cuenta/comentarios",
    component: _5f082948,
    props: false,
    name: "profile-comments___es"
  }, {
    path: "/es/mi-cuenta/credito-comercial",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___es"
  }, {
    path: "/es/mi-cuenta/editar",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___es"
  }, {
    path: "/es/mi-cuenta/mensajes",
    component: _a6af557e,
    props: false,
    name: "messenger___es"
  }, {
    path: "/es/mi-cuenta/pedidos",
    component: _1b183c90,
    props: false,
    name: "profile-orders___es"
  }, {
    path: "/es/panel-vendedor/agregar-oferta",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___es"
  }, {
    path: "/es/panel-vendedor/anadir-producto",
    component: _682611ef,
    props: false,
    name: "admin-product-add___es"
  }, {
    path: "/es/panel-vendedor/anadir-tamano",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___es"
  }, {
    path: "/es/panel-vendedor/cambiar-contrasena",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___es"
  }, {
    path: "/es/panel-vendedor/client-groups",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___es"
  }, {
    path: "/es/panel-vendedor/clientes",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___es"
  }, {
    path: "/es/panel-vendedor/credito-comercial",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___es"
  }, {
    path: "/es/panel-Vendedor/editar",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___es"
  }, {
    path: "/es/panel-vendedor/empleados",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___es"
  }, {
    path: "/es/panel-vendedor/Envio",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___es"
  }, {
    path: "/es/panel-vendedor/filters",
    component: _5342f069,
    props: false,
    name: "admin-filters___es"
  }, {
    path: "/es/panel-vendedor/importacion-exportacion",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___es"
  }, {
    path: "/es/panel-vendedor/instrumentos",
    component: _b269f86e,
    props: false,
    name: "admin-tools___es"
  }, {
    path: "/es/panel-vendedor/marketing",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___es"
  }, {
    path: "/es/panel-Vendedor/mensajes",
    component: _56413e01,
    props: false,
    name: "admin-messenger___es"
  }, {
    path: "/es/panel-vendedor/ofertas",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___es"
  }, {
    path: "/es/panel-vendedor/ofertas-cupones",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___es"
  }, {
    path: "/es/panel-vendedor/page",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___es"
  }, {
    path: "/es/panel-vendedor/pago",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___es"
  }, {
    path: "/es/panel-vendedor/pedido",
    component: _3807c62a,
    props: false,
    name: "admin-orders___es"
  }, {
    path: "/es/panel-vendedor/plantillas-de-descripcion",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___es"
  }, {
    path: "/es/panel-vendedor/post",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___es"
  }, {
    path: "/es/panel-vendedor/precios-en-cantidad",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___es"
  }, {
    path: "/es/panel-vendedor/productos",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___es"
  }, {
    path: "/es/panel-vendedor/tamanos",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___es"
  }, {
    path: "/es/panel-Vendedor/tienda-edicion",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___es"
  }, {
    path: "/es/panel-vendedor/updatePage",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___es"
  }, {
    path: "/es/panel-vendedor/updatePost",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___es"
  }, {
    path: "/es/Telefono/Confirmacion",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___es"
  }, {
    path: "/fr/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___fr"
  }, {
    path: "/fr/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___fr"
  }, {
    path: "/fr/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___fr"
  }, {
    path: "/fr/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___fr"
  }, {
    path: "/fr/mon-compte/changer-mot-de-passe",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___fr"
  }, {
    path: "/fr/mon-compte/commandes",
    component: _1b183c90,
    props: false,
    name: "profile-orders___fr"
  }, {
    path: "/fr/mon-compte/commentaires",
    component: _5f082948,
    props: false,
    name: "profile-comments___fr"
  }, {
    path: "/fr/mon-compte/credit-commercial",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___fr"
  }, {
    path: "/fr/mon-compte/editer",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___fr"
  }, {
    path: "/fr/mon-compte/messages",
    component: _a6af557e,
    props: false,
    name: "messenger___fr"
  }, {
    path: "/fr/panneau-vendeur/ajouter-offre",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___fr"
  }, {
    path: "/fr/panneau-vendeur/ajouter-produit",
    component: _682611ef,
    props: false,
    name: "admin-product-add___fr"
  }, {
    path: "/fr/panneau-vendeur/ajouter-taille",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___fr"
  }, {
    path: "/fr/panneau-vendeur/changer-mot-de-passe",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___fr"
  }, {
    path: "/fr/panneau-vendeur/client-groups",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___fr"
  }, {
    path: "/fr/panneau-vendeur/clients",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___fr"
  }, {
    path: "/fr/panneau-vendeur/commandes",
    component: _3807c62a,
    props: false,
    name: "admin-orders___fr"
  }, {
    path: "/fr/panneau-vendeur/commercialisation",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___fr"
  }, {
    path: "/fr/panneau-vendeur/coupons-offres",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___fr"
  }, {
    path: "/fr/panneau-vendeur/credit-commercial",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___fr"
  }, {
    path: "/fr/panneau-vendeur/editer",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/employes",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___fr"
  }, {
    path: "/fr/panneau-vendeur/expedition",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___fr"
  }, {
    path: "/fr/panneau-vendeur/filtres",
    component: _5342f069,
    props: false,
    name: "admin-filters___fr"
  }, {
    path: "/fr/panneau-vendeur/import-export",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___fr"
  }, {
    path: "/fr/panneau-vendeur/magasin-edition",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/messages",
    component: _56413e01,
    props: false,
    name: "admin-messenger___fr"
  }, {
    path: "/fr/panneau-vendeur/modeles-de-description",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___fr"
  }, {
    path: "/fr/panneau-vendeur/offres",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___fr"
  }, {
    path: "/fr/panneau-vendeur/outils",
    component: _b269f86e,
    props: false,
    name: "admin-tools___fr"
  }, {
    path: "/fr/panneau-vendeur/page",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___fr"
  }, {
    path: "/fr/panneau-vendeur/paiement",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___fr"
  }, {
    path: "/fr/panneau-vendeur/post",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___fr"
  }, {
    path: "/fr/panneau-vendeur/prix-sur-quantite",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___fr"
  }, {
    path: "/fr/panneau-vendeur/produits",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___fr"
  }, {
    path: "/fr/panneau-vendeur/tailles",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___fr"
  }, {
    path: "/fr/panneau-vendeur/updatePage",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___fr"
  }, {
    path: "/fr/panneau-vendeur/updatePost",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___fr"
  }, {
    path: "/fr/telephone/confirmer",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___fr"
  }, {
    path: "/it/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___it"
  }, {
    path: "/it/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___it"
  }, {
    path: "/it/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___it"
  }, {
    path: "/it/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___it"
  }, {
    path: "/it/il-mio-conto/cambiamento-fastidio",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___it"
  }, {
    path: "/it/il-mio-conto/credito-commerciale",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___it"
  }, {
    path: "/it/il-mio-conto/modifica",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___it"
  }, {
    path: "/it/il-mio-conto/notizie",
    component: _a6af557e,
    props: false,
    name: "messenger___it"
  }, {
    path: "/it/il-mio-conto/opinioni",
    component: _5f082948,
    props: false,
    name: "profile-comments___it"
  }, {
    path: "/it/il-mio-conto/ordini",
    component: _1b183c90,
    props: false,
    name: "profile-orders___it"
  }, {
    path: "/it/pannello-venditori/aggiornamentoPagina",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___it"
  }, {
    path: "/it/pannello-venditori/aggiornamentoPost",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___it"
  }, {
    path: "/it/pannello-venditori/aggiungi prodotto",
    component: _682611ef,
    props: false,
    name: "admin-product-add___it"
  }, {
    path: "/it/pannello-venditori/aggiungi-dimensione",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___it"
  }, {
    path: "/it/pannello-venditori/aggiungi-offerta",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___it"
  }, {
    path: "/it/pannello-venditori/cambiamento-fastidio",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___it"
  }, {
    path: "/it/pannello-venditori/clienti",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___it"
  }, {
    path: "/it/pannello-venditori/coupon-offerte",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___it"
  }, {
    path: "/it/pannello-venditori/credito-commerciale",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___it"
  }, {
    path: "/it/pannello-venditori/dimensioni",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___it"
  }, {
    path: "/it/pannello-venditori/dipendenti",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___it"
  }, {
    path: "/it/pannello-venditori/filtri",
    component: _5342f069,
    props: false,
    name: "admin-filters___it"
  }, {
    path: "/it/pannello-venditori/gruppi-clienti",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___it"
  }, {
    path: "/it/pannello-venditori/importazione-esportazione",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___it"
  }, {
    path: "/it/pannello-venditori/marketing",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___it"
  }, {
    path: "/it/pannello-venditori/modelli-di-descrizione",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___it"
  }, {
    path: "/it/pannello-venditori/modifica",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___it"
  }, {
    path: "/it/pannello-venditori/modifica-del-negozio",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___it"
  }, {
    path: "/it/pannello-venditori/notizie",
    component: _56413e01,
    props: false,
    name: "admin-messenger___it"
  }, {
    path: "/it/pannello-venditori/offerte",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___it"
  }, {
    path: "/it/pannello-venditori/ordini",
    component: _3807c62a,
    props: false,
    name: "admin-orders___it"
  }, {
    path: "/it/pannello-venditori/pagamenti",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___it"
  }, {
    path: "/it/pannello-venditori/pagina",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___it"
  }, {
    path: "/it/pannello-venditori/posta",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___it"
  }, {
    path: "/it/pannello-venditori/prezzi-dal-chilometraggio",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___it"
  }, {
    path: "/it/pannello-venditori/prodotti",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___it"
  }, {
    path: "/it/pannello-venditori/spedizioni",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___it"
  }, {
    path: "/it/pannello-venditori/strumenti",
    component: _b269f86e,
    props: false,
    name: "admin-tools___it"
  }, {
    path: "/it/telefonica/conferma",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___it"
  }, {
    path: "/nl/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___nl"
  }, {
    path: "/nl/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___nl"
  }, {
    path: "/nl/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___nl"
  }, {
    path: "/nl/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___nl"
  }, {
    path: "/nl/mijn-account/berichten",
    component: _a6af557e,
    props: false,
    name: "messenger___nl"
  }, {
    path: "/nl/mijn-account/bestellingen",
    component: _1b183c90,
    props: false,
    name: "profile-orders___nl"
  }, {
    path: "/nl/mijn-account/bewerken",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___nl"
  }, {
    path: "/nl/mijn-account/handelskrediet",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___nl"
  }, {
    path: "/nl/mijn-account/opmerkingen",
    component: _5f082948,
    props: false,
    name: "profile-comments___nl"
  }, {
    path: "/nl/mijn-account/wachtwoord-wijzigen",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___nl"
  }, {
    path: "/nl/telefoon/bevestigen",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___nl"
  }, {
    path: "/nl/verkoper-paneel/aanbiedingen",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___nl"
  }, {
    path: "/nl/verkoper-paneel/asd",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___nl"
  }, {
    path: "/nl/verkoper-paneel/bericht-bijwerken",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___nl"
  }, {
    path: "/nl/verkoper-paneel/berichten",
    component: _56413e01,
    props: false,
    name: "admin-messenger___nl"
  }, {
    path: "/nl/verkoper-paneel/beschrijvingssjablonen",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___nl"
  }, {
    path: "/nl/verkoper-paneel/bestellingen",
    component: _3807c62a,
    props: false,
    name: "admin-orders___nl"
  }, {
    path: "/nl/verkoper-paneel/betaling",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___nl"
  }, {
    path: "/nl/verkoper-paneel/bladzijde",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___nl"
  }, {
    path: "/nl/verkoper-paneel/coupons-deals",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___nl"
  }, {
    path: "/nl/verkoper-paneel/filters",
    component: _5342f069,
    props: false,
    name: "admin-filters___nl"
  }, {
    path: "/nl/verkoper-paneel/handelskrediet",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___nl"
  }, {
    path: "/nl/verkoper-paneel/hulpmiddelen",
    component: _b269f86e,
    props: false,
    name: "admin-tools___nl"
  }, {
    path: "/nl/verkoper-paneel/import-export",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___nl"
  }, {
    path: "/nl/verkoper-paneel/klantgroepen",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___nl"
  }, {
    path: "/nl/verkoper-paneel/maat-toevoegen",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___nl"
  }, {
    path: "/nl/verkoper-paneel/maten",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___nl"
  }, {
    path: "/nl/verkoper-paneel/medewerkers",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___nl"
  }, {
    path: "/nl/verkoper-paneel/pagina-bijwerken",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___nl"
  }, {
    path: "/nl/verkoper-paneel/post",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___nl"
  }, {
    path: "/nl/verkoper-paneel/prijzen-op-hoeveelheid",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___nl"
  }, {
    path: "/nl/verkoper-paneel/product-toevoegen",
    component: _682611ef,
    props: false,
    name: "admin-product-add___nl"
  }, {
    path: "/nl/verkoper-paneel/producten",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___nl"
  }, {
    path: "/nl/verkoper-paneel/toevoegen",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___nl"
  }, {
    path: "/nl/verkoper-paneel/toevoegen-aanbieding",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___nl"
  }, {
    path: "/nl/verkoper-paneel/verzenden",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___nl"
  }, {
    path: "/nl/verkoper-paneel/verzenden",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___nl"
  }, {
    path: "/nl/verkoper-paneel/wachtwoord-wijzigen",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___nl"
  }, {
    path: "/nl/verkoper-panel/edit",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___nl"
  }, {
    path: "/nl/verkoper-panel/klanten",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___nl"
  }, {
    path: "/nl/verkoper-panel/marketing",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___nl"
  }, {
    path: "/nl/verkoper-panel/winkeleditie",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___nl"
  }, {
    path: "/no/admin/bank-transfer",
    component: _74c1586c,
    props: false,
    name: "admin-bank-transfer___no"
  }, {
    path: "/no/admin/invoice",
    component: _53e1a69b,
    props: false,
    name: "admin-invoice___no"
  }, {
    path: "/no/admin/messenger-statistic",
    component: _050e9104,
    props: false,
    name: "admin-messenger-statistic___no"
  }, {
    path: "/no/b2b/success",
    component: _444a4718,
    props: false,
    name: "b2b-success___no"
  }, {
    path: "/no/min-konto/bestillinger",
    component: _1b183c90,
    props: false,
    name: "profile-orders___no"
  }, {
    path: "/no/min-konto/endring-av-passord",
    component: _9d5a9040,
    props: false,
    name: "profile-change-password___no"
  }, {
    path: "/no/min-konto/handel-kreditt",
    component: _3d119a76,
    props: false,
    name: "profile-tradeCredit___no"
  }, {
    path: "/no/min-konto/kommentarer",
    component: _5f082948,
    props: false,
    name: "profile-comments___no"
  }, {
    path: "/no/min-konto/meldinger",
    component: _a6af557e,
    props: false,
    name: "messenger___no"
  }, {
    path: "/no/min-konto/rediger",
    component: _7b9c6872,
    props: false,
    name: "profile-edit___no"
  }, {
    path: "/no/selger-panel/administrer-kunde-grupper",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___no"
  }, {
    path: "/no/selger-panel/ansatte",
    component: _c05dc12c,
    props: false,
    name: "admin-employees-list___no"
  }, {
    path: "/no/selger-panel/beskrivelse-maler",
    component: _02c61d84,
    props: false,
    name: "admin-descriptionTemplates-list___no"
  }, {
    path: "/no/selger-panel/bestillinger",
    component: _3807c62a,
    props: false,
    name: "admin-orders___no"
  }, {
    path: "/no/selger-panel/betaling",
    component: _2b7280f4,
    props: false,
    name: "admin-payment___no"
  }, {
    path: "/no/selger-panel/butikk-redigering",
    component: _75f989fa,
    props: false,
    name: "admin-store-edit___no"
  }, {
    path: "/no/selger-panel/endre-passord",
    component: _0ededff4,
    props: false,
    name: "admin-change-password___no"
  }, {
    path: "/no/selger-panel/filtre",
    component: _5342f069,
    props: false,
    name: "admin-filters___no"
  }, {
    path: "/no/selger-panel/frakt",
    component: _4b5ce4b0,
    props: false,
    name: "admin-shipping___no"
  }, {
    path: "/no/selger-panel/handel-kreditt",
    component: _4629f41c,
    props: false,
    name: "admin-tradeCredit-list___no"
  }, {
    path: "/no/selger-panel/import-eksport",
    component: _3ab0bcba,
    props: false,
    name: "admin-spreadsheet___no"
  }, {
    path: "/no/selger-panel/kunde-grupper",
    component: _47fde8c4,
    props: false,
    name: "admin-clientGroups___no"
  }, {
    path: "/no/selger-panel/kunder",
    component: _05e91ec7,
    props: false,
    name: "admin-clients-list___no"
  }, {
    path: "/no/selger-panel/kuponger-avtaler",
    component: _ebadbf40,
    props: false,
    name: "admin-coupons&groupDeal___no"
  }, {
    path: "/no/selger-panel/legg-til-ansatt",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___no"
  }, {
    path: "/no/selger-panel/legg-til-gruppeavtale",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___no"
  }, {
    path: "/no/selger-panel/legg-til-produkt",
    component: _682611ef,
    props: false,
    name: "admin-product-add___no"
  }, {
    path: "/no/selger-panel/legg-til-storrelse",
    component: _0dcb3012,
    props: false,
    name: "admin-sizes-add___no"
  }, {
    path: "/no/selger-panel/legg-til-tilbud",
    component: _0687bfdc,
    props: false,
    name: "admin-offer-add___no"
  }, {
    path: "/no/selger-panel/markedsforing",
    component: _5c4eac2e,
    props: false,
    name: "admin-marketing-list___no"
  }, {
    path: "/no/selger-panel/meldinger",
    component: _56413e01,
    props: false,
    name: "admin-messenger___no"
  }, {
    path: "/no/selger-panel/oppdater-innlegget",
    component: _0a29eede,
    props: false,
    name: "admin-cms-updatePost___no"
  }, {
    path: "/no/selger-panel/oppdater-side",
    component: _01064300,
    props: false,
    name: "admin-cms-updatePage___no"
  }, {
    path: "/no/selger-panel/post",
    component: _3645ceb0,
    props: false,
    name: "admin-cms-post___no"
  }, {
    path: "/no/selger-panel/priser-pa-antall",
    component: _71a73176,
    props: false,
    name: "admin-pricesOnQuantity___no"
  }, {
    path: "/no/selger-panel/produkter",
    component: _391aabc0,
    props: false,
    name: "admin-product-list___no"
  }, {
    path: "/no/selger-panel/rediger",
    component: _e76e2ca0,
    props: false,
    name: "admin-profile-edit___no"
  }, {
    path: "/no/selger-panel/side",
    component: _2a0f59d2,
    props: false,
    name: "admin-cms-page___no"
  }, {
    path: "/no/selger-panel/storrelser",
    component: _481951fd,
    props: false,
    name: "admin-sizes-list___no"
  }, {
    path: "/no/selger-panel/tilbud",
    component: _66eebb73,
    props: false,
    name: "admin-offer-list___no"
  }, {
    path: "/no/selger-panel/verktoy",
    component: _b269f86e,
    props: false,
    name: "admin-tools___no"
  }, {
    path: "/no/telefon/bekreftelse",
    component: _3bc4593c,
    props: false,
    name: "auth-confirm-phone___no"
  }, {
    path: "/Verkaufer-Panel/Beschreibungsvorlagen/hinzufugen",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___de"
  }, {
    path: "/Verkaufer-Panel/Bestellungen/hinzufugen",
    component: _65898679,
    props: false,
    name: "admin-orders-add___de"
  }, {
    path: "/Verkaufer-Panel/client-groups/manage",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___de"
  }, {
    path: "/Verkaufer-Panel/groupDeal/hinzufugen",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___de"
  }, {
    path: "/Verkaufer-Panel/marketing/hinzufugen",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___de"
  }, {
    path: "/Verkaufer-Panel/Mitarbeiter/Hinzufugen",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___de"
  }, {
    path: "/en/seller-panel/client-groups/manage",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___en"
  }, {
    path: "/en/seller-panel/description-templates/add",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___en"
  }, {
    path: "/en/seller-panel/employees/add",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___en"
  }, {
    path: "/en/seller-panel/groupDeal/add",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___en"
  }, {
    path: "/en/seller-panel/marketing/add",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___en"
  }, {
    path: "/en/seller-panel/orders/add",
    component: _65898679,
    props: false,
    name: "admin-orders-add___en"
  }, {
    path: "/es/panel-vendedor/client-groups/manage",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___es"
  }, {
    path: "/es/panel-vendedor/empleados/anada",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___es"
  }, {
    path: "/es/panel-vendedor/groupDeal/add",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___es"
  }, {
    path: "/es/panel-vendedor/marketing/agregar",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___es"
  }, {
    path: "/es/panel-vendedor/pedido/add",
    component: _65898679,
    props: false,
    name: "admin-orders-add___es"
  }, {
    path: "/es/panel-vendedor/plantillas-de-descripcion/agregar",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___es"
  }, {
    path: "/fr/panneau-vendeur/client-groups/manage",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___fr"
  }, {
    path: "/fr/panneau-vendeur/commandes/ajouter",
    component: _65898679,
    props: false,
    name: "admin-orders-add___fr"
  }, {
    path: "/fr/panneau-vendeur/commercialisation/ajouter",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___fr"
  }, {
    path: "/fr/panneau-vendeur/employes/ajouter",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___fr"
  }, {
    path: "/fr/panneau-vendeur/groupDeal/ajouter",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___fr"
  }, {
    path: "/fr/panneau-vendeur/modeles-de-description/ajouter",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___fr"
  }, {
    path: "/it/pannello-venditori/dipendenti/aggiungere",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___it"
  }, {
    path: "/it/pannello-venditori/groupDeal/aggiungere",
    component: _20323b0b,
    props: false,
    name: "admin-groupDeal-add___it"
  }, {
    path: "/it/pannello-venditori/gruppi-clienti/gestire",
    component: _22821a63,
    props: false,
    name: "admin-clientGroups-manage___it"
  }, {
    path: "/it/pannello-venditori/marketing/aggiungere",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___it"
  }, {
    path: "/it/pannello-venditori/marketing/aggiungere",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___it"
  }, {
    path: "/it/pannello-venditori/ordini/aggiungi",
    component: _65898679,
    props: false,
    name: "admin-orders-add___it"
  }, {
    path: "/nl/verkoper-paneel/beschrijvingssjablonen/add",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___nl"
  }, {
    path: "/nl/verkoper-paneel/bestellingen/add",
    component: _65898679,
    props: false,
    name: "admin-orders-add___nl"
  }, {
    path: "/nl/verkoper-paneel/medewerkers/toevoegen",
    component: _085d8105,
    props: false,
    name: "admin-employees-add___nl"
  }, {
    path: "/nl/verkoper-panel/marketing/add",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___nl"
  }, {
    path: "/no/selger-panel/beskrivelse-maler/legg-til",
    component: _1ab3b69e,
    props: false,
    name: "admin-descriptionTemplates-add___no"
  }, {
    path: "/no/selger-panel/bestillinger/legg-til",
    component: _65898679,
    props: false,
    name: "admin-orders-add___no"
  }, {
    path: "/no/selger-panel/markedsforing/legg-til",
    component: _4c0b2aa6,
    props: false,
    name: "admin-marketing-add___no"
  }, {
    path: "/en/seller-panel/description-templates/edit/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___en"
  }, {
    path: "/en/seller-panel/marketing/edit/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___en"
  }, {
    path: "/es/panel-vendedor/marketing/editar/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___es"
  }, {
    path: "/es/panel-vendedor/plantillas-de-descripcion/editar/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___es"
  }, {
    path: "/fr/panneau-vendeur/commercialisation/editer/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/modeles-de-description/editer/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___fr"
  }, {
    path: "/it/pannello-venditori/marketing/modificare/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___it"
  }, {
    path: "/it/pannello-venditori/modelli-di-descrizione/modificare/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___it"
  }, {
    path: "/nl/verkoper-paneel/beschrijvingssjablonen/edit/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___nl"
  }, {
    path: "/nl/verkoper-paneel/medewerkers/bewerking/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___nl"
  }, {
    path: "/nl/verkoper-panel/marketing/edit/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___nl"
  }, {
    path: "/no/selger-panel/beskrivelse-maler/rediger/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___no"
  }, {
    path: "/no/selger-panel/markedsforing/rediger/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___no"
  }, {
    path: "/en/email/confirm/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___en"
  }, {
    path: "/en/my-account/order/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___en"
  }, {
    path: "/en/seller-panel/client/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___en"
  }, {
    path: "/en/seller-panel/deals/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___en"
  }, {
    path: "/en/seller-panel/employees/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___en"
  }, {
    path: "/en/seller-panel/groupDeal/:id",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___en"
  }, {
    path: "/en/seller-panel/offer/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___en"
  }, {
    path: "/en/seller-panel/order/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___en"
  }, {
    path: "/en/seller-panel/product/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___en"
  }, {
    path: "/en/seller-panel/size/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___en"
  }, {
    path: "/en/seller-panel/trade-credit/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___en"
  }, {
    path: "/es/Correo-electronico/Confirmacion/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___es"
  }, {
    path: "/es/mi-cuenta/pedido/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___es"
  }, {
    path: "/es/panel-vendedor/cliente/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___es"
  }, {
    path: "/es/panel-vendedor/credito-comercial/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___es"
  }, {
    path: "/es/panel-vendedor/deals/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___es"
  }, {
    path: "/es/panel-vendedor/empleados/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___es"
  }, {
    path: "/es/panel-vendedor/groupDeal/:id",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___es"
  }, {
    path: "/es/panel-vendedor/oferta/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___es"
  }, {
    path: "/es/panel-vendedor/pedido/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___es"
  }, {
    path: "/es/panel-vendedor/producto/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___es"
  }, {
    path: "/es/panel-vendedor/tamano/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___es"
  }, {
    path: "/fr/email/confirmer/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___fr"
  }, {
    path: "/fr/mon-compte/commande/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___fr"
  }, {
    path: "/fr/panneau-vendeur/client/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___fr"
  }, {
    path: "/fr/panneau-vendeur/commande/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___fr"
  }, {
    path: "/fr/panneau-vendeur/credit-commercial/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___fr"
  }, {
    path: "/fr/panneau-vendeur/deals/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___fr"
  }, {
    path: "/fr/panneau-vendeur/employes/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/groupDeal/:id",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/offre/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/produit/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___fr"
  }, {
    path: "/fr/panneau-vendeur/taille/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___fr"
  }, {
    path: "/it/email/conferma/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___it"
  }, {
    path: "/it/il-mio-conto/ordine/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___it"
  }, {
    path: "/it/pannello-venditori/cliente/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___it"
  }, {
    path: "/it/pannello-venditori/credito-commerciale/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___it"
  }, {
    path: "/it/pannello-venditori/deals/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___it"
  }, {
    path: "/it/pannello-venditori/dimensione/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___it"
  }, {
    path: "/it/pannello-venditori/dipendenti/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___it"
  }, {
    path: "/it/pannello-venditori/groupDeal/:id",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___it"
  }, {
    path: "/it/pannello-venditori/offerta/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___it"
  }, {
    path: "/it/pannello-venditori/ordine/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___it"
  }, {
    path: "/it/pannello-venditori/prodotto/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___it"
  }, {
    path: "/nl/email/bevestigen/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___nl"
  }, {
    path: "/nl/mijn-account/bestelling/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___nl"
  }, {
    path: "/nl/verkoper-paneel/aanbieding/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___nl"
  }, {
    path: "/nl/verkoper-paneel/bestelling/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___nl"
  }, {
    path: "/nl/verkoper-paneel/deals/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___nl"
  }, {
    path: "/nl/verkoper-paneel/Handelskrediet/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___nl"
  }, {
    path: "/nl/verkoper-paneel/maat/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___nl"
  }, {
    path: "/nl/verkoper-paneel/product/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___nl"
  }, {
    path: "/nl/verkoper-panel/client/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___nl"
  }, {
    path: "/no/e-post/bekreftelse/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___no"
  }, {
    path: "/no/min-konto/bestilling/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___no"
  }, {
    path: "/no/selger-panel/ansatte/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___no"
  }, {
    path: "/no/selger-panel/avtaler/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___no"
  }, {
    path: "/no/selger-panel/bestilling/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___no"
  }, {
    path: "/no/selger-panel/gruppeavtale/:id",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___no"
  }, {
    path: "/no/selger-panel/handel-kreditt/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___no"
  }, {
    path: "/no/selger-panel/kunde/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___no"
  }, {
    path: "/no/selger-panel/produkt/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___no"
  }, {
    path: "/no/selger-panel/storrelse/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___no"
  }, {
    path: "/no/selger-panel/tilbud/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___no"
  }, {
    path: "/Verkaufer-Panel/Beschreibungsvorlagen/bearbeiten/:id",
    component: _0de518aa,
    props: false,
    name: "admin-descriptionTemplates-edit___de"
  }, {
    path: "/Verkaufer-Panel/marketing/bearbeiten/:id",
    component: _3dbe5d56,
    props: false,
    name: "admin-marketing-edit___de"
  }, {
    path: "/E-Mail/Bestatigung/:token",
    component: _1106bf2c,
    props: false,
    name: "auth-confirm-email___de"
  }, {
    path: "/en/groupDeal/:identify",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___en"
  }, {
    path: "/en/reset-password/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___en"
  }, {
    path: "/es/groupDeal/:identificar",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___es"
  }, {
    path: "/es/Restablecer-contrasena/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___es"
  }, {
    path: "/fr/groupDeal /:identifier",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___fr"
  }, {
    path: "/fr/reinitialiser-le-mot-de-passe/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___fr"
  }, {
    path: "/it/groupDeal/:identificar",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___it"
  }, {
    path: "/it/reimpostare-le-password/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___it"
  }, {
    path: "/mein-Konto/Bestellung/:id",
    component: _b4a7ba10,
    props: true,
    name: "profile-orders-id___de"
  }, {
    path: "/nl/groupDeal/:identificeren",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___nl"
  }, {
    path: "/nl/wachtwoord-opnieuw-instellen/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___nl"
  }, {
    path: "/no/groupDeal/:identificeren",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___no"
  }, {
    path: "/no/tilbakestille-passord/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___no"
  }, {
    path: "/Verkaufer-Panel/Angebot/:id",
    component: _7636e2df,
    props: false,
    name: "admin-offer-edit___de"
  }, {
    path: "/Verkaufer-Panel/Bestellung/:id",
    component: _04454b12,
    props: true,
    name: "admin-orders-id___de"
  }, {
    path: "/Verkaufer-Panel/deals/:groupDeal",
    component: _67e4b71e,
    props: false,
    name: "admin-groupDeal-deals___de"
  }, {
    path: "/Verkaufer-Panel/Grosse/:id",
    component: _57617969,
    props: false,
    name: "admin-sizes-edit___de"
  }, {
    path: "/Verkaufer-Panel/groupDeal/:id",
    component: _dc4864e0,
    props: false,
    name: "admin-groupDeal-edit___de"
  }, {
    path: "/Verkaufer-Panel/Handelskredit/:id",
    component: _00112ec4,
    props: false,
    name: "admin-tradeCredit-details___de"
  }, {
    path: "/Verkaufer-Panel/Kunde/:id",
    component: _598323a9,
    props: false,
    name: "admin-clients-details___de"
  }, {
    path: "/Verkaufer-Panel/Mitarbeiter/:id",
    component: _a1cd7254,
    props: false,
    name: "admin-employees-edit___de"
  }, {
    path: "/Verkaufer-Panel/Produkte/:id",
    component: _4862d32c,
    props: false,
    name: "admin-product-edit___de"
  }, {
    path: "/en/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___en"
  }, {
    path: "/es/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___es"
  }, {
    path: "/fr/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___fr"
  }, {
    path: "/groupDeal/:identify",
    component: _1cb2a48e,
    props: false,
    name: "groupDeal___de"
  }, {
    path: "/it/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___it"
  }, {
    path: "/nl/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___nl"
  }, {
    path: "/no/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___no"
  }, {
    path: "/Passwort-zurucksetzen/:token",
    component: _323270ce,
    props: false,
    name: "auth-reset-password___de"
  }, {
    path: "/no/*",
    component: _1608acd8,
    props: false,
    name: "*___no"
  }, {
    path: "/nl/*",
    component: _1608acd8,
    props: false,
    name: "*___nl"
  }, {
    path: "/it/*",
    component: _1608acd8,
    props: false,
    name: "*___it"
  }, {
    path: "/es/*",
    component: _1608acd8,
    props: false,
    name: "*___es"
  }, {
    path: "/fr/*",
    component: _1608acd8,
    props: false,
    name: "*___fr"
  }, {
    path: "/en/*",
    component: _1608acd8,
    props: false,
    name: "*___en"
  }, {
    path: "/",
    component: _0f047300,
    props: false,
    name: "index___de"
  }, {
    path: "/:slug",
    component: _a459ced2,
    props: false,
    name: "cms-postPage___de"
  }, {
    path: "/*",
    component: _1608acd8,
    props: false,
    name: "*___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
