<template>
  <div>
    <cookie-alert />
    <scroll-to-top v-if="!hideScrollTop" />
    <cart-trigger v-if="!hideCartTrigger" />
  </div>
</template>

<script>
import cookieAlert from 'marketplace-front-core/components/layout/CookieAlert'
import ScrollToTop from 'marketplace-front-core/components/layout/ScrollToTop'
import CartTrigger from 'marketplace-front-core/components/layout/CartTrigger'

export default {
  components: {
    cookieAlert,
    ScrollToTop,
    CartTrigger
  },
  props: {
    hideScrollTop: {
      type: Boolean,
      default: false
    },
    hideCartTrigger: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (process.env.facebookPixedId && this.$fb && this.$fb.fbq && this.user) {
      const check = ['ROLE_SELLER_OWNER', 'ROLE_SELLER'].filter(val =>
        this.user.roles.includes(val)
      )

      if (check.length > 0) {
        this.$fb.disable()
      }
    }

    setTimeout(() => {
      this.initWebSocket()
    }, 500)
  }

}
</script>
