<template>
  <b-dropdown v-if="locales.length >= 2" :class="[{'target-messenger': target === 'messenger'}]">
    <div slot="trigger" slot-scope="{ active }" class="language" :class="[{'target-messenger': target === 'messenger'}]">
      <div class="language-flag">
        <div class="flag-circle">
          <div v-if="target !== 'messenger'" class="img-flag" v-html="flag[locale]" />
          <div v-else class="img-flag" v-html="flag[ticketLang]" />
        </div>
      </div>
      <!-- <span>
        {{ target !== 'messenger' ? translatedLocale(locale): ticketLang.toUpperCase() }}
      </span> -->
    </div>
    <b-dropdown-item v-for="lang in filteredLocales" :key="lang" has-link>
      <template v-if="target === 'navigation-menu'">
        <div class="language-item " @click="switchLanguage(lang)">
          <div class="img-flag" v-html="flag[lang]" />
          <div>
            {{ translatedLocale(lang) }}
          </div>
        </div>
      </template>
      <template v-if="target === 'messenger'">
        <div class="language-item" @click="switchTicketLanguage(lang)">
          <div class="img-flag" style="width: 16px;" v-html="flag[lang]" />
          <span>
            {{ lang.toUpperCase() }}
          </span>
        </div>
      </template>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import pl from 'marketplace-front-core/static/icons/flags/pl.svg?raw'
import de from 'marketplace-front-core/static/icons/flags/de.svg?raw'
import en from 'marketplace-front-core/static/icons/flags/en.svg?raw'
import fr from 'marketplace-front-core/static/icons/flags/fr.svg?raw'
import es from 'marketplace-front-core/static/icons/flags/es.svg?raw'
import it from 'marketplace-front-core/static/icons/flags/it.svg?raw'
import nl from 'marketplace-front-core/static/icons/flags/nl.svg?raw'
import no from 'marketplace-front-core/static/icons/flags/no.svg?raw'

export default {
  props: {
    target: {
      type: String,
      default: 'navigation-menu'
    },
    ticketLang: {
      type: String,
      default: 'de'
    }
  },
  data () {
    return {
      flag: {
        pl,
        de,
        en,
        fr,
        es,
        it,
        nl,
        no
      }
    }
  },
  computed: {
    locales () {
      return process.env.langs.split('|')
    },
    locale () {
      return this.$store.state.i18n.locale
    },
    hiddenLangs () {
      return process.env.hiddenLangs ? process.env.hiddenLangs.split('|') : []
    },
    filteredLocales () {
      return this.locales.filter(x => !this.hiddenLangs.includes(x))
    }
  },
  methods: {
    translatedLocale (lang) {
      switch (lang) {
        case 'en':
          return 'English'
        case 'de':
          return 'Deutsch'
        case 'fr':
          return 'Français'
        case 'es':
          return 'Español'
        case 'it':
          return 'Italiano'
        case 'nl':
          return 'Nederlands'
        case 'no':
          return 'Nynorsk'
        case 'pl':
          return 'Polski'
        default:
          return 'Undefined'
      }
    },
    switchLanguage (lang) {
      this.$router.push(this.switchLocalePath(lang))
    },
    switchTicketLanguage (lang) {
      this.$emit('switchTicketLanguage', lang)
    }
  }
}
</script>

<style lang="scss" scoped>
.language {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 1rem;
  cursor: pointer;
  &.target-messenger {
    gap: 4px;
    margin: 0;
    font-size: 14px;
    .language-flag {
      width: 24px;
      height: 24px;
      border: 1px solid #e4e4e4;
      .flag-circle {
        width: 14px;
        height: 14px;
      }
    }
  }
  .language-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    // border: 1.5px solid #E4E4E4;
    border: none;
    border-radius: 50%;
    .flag-circle {
      width: 24px;
      height: 24px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      .img-flag {
        display: inline;
        width: auto;
        height: 100%;
      }
    }
  }
}
.language-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-size: 14px;
  &:hover {
    background-color: #F7F7F7;
    cursor: pointer;
  }
  .img-flag {
    display: block;
    width: 20px;
    height: 20px;
  }
}
.dropdown.target-messenger {
  :deep(.dropdown-menu) {
    left: 0px !important;
  }
}
:deep(.dropdown-menu) {
  left: 8px;
}
</style>
